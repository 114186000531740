body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Reenie Beanie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/reeniebeanie/v20/z7NSdR76eDkaJKZJFkkjuvWxXPq1qw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.link {
  font-weight: 500;
  font-size: 15px;
  border-bottom: 1px solid black;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 4px;
}

.pagination li a {
  color: #FE3700;
  border: 1px solid #FE3700;
  padding: 8px 12px;
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none;
}

.pagination li a:hover {
  background-color: #ebf8ff;
}

.pagination li.active a {
  background-color: #FE3700;
  color: white;
  border: 1px solid #FE3700;
}

.pagination li.disabled a {
  color: gray;
  border: 1px solid gray;
  cursor: not-allowed;
}

.break-me a {
  border: none;
  background: transparent;
}

.slick-prev:before, .slick-next:before {
  color:  black !important;
  font-size: 25px !important;
}

ul, ol{
  padding-left: 30px !important;
}

.description_image img {
  height: auto !important;
  width: auto !important;
}

@media screen and (max-width: 600px) {
  .pagination {
    justify-content: flex-end;
  }
  .pagination li a {
    padding: 4px 8px;
  }
  .pagination li {
    margin: 0 2px;
  }
  .description_image img {
    height: 100% !important;
    width: 100% !important;
  }
}